import { global } from "@/config/global";
import { getSession } from "./sessionHelper";
import { jsonToParam } from "./globalHelpers";

// Get session data if exists
const sessionData = getSession();

// Global API GET function
export async function apiGet(requestPath, paramsJson, token) {
  // Create request options (if exists) with credentials
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    redirect: "follow",
  };

  if (token) {
    requestOptions.headers["Authorization"] = "Bearer " + token;
  }

  try {
    // Create fetch url with parameters
    const params = jsonToParam(paramsJson);
    const url = global.api.base + requestPath + params;

    // Fetch data
    const raw = await fetch(url, requestOptions);

    // Check if the request was successful
    if (!raw.ok) {
      // You can throw a new error here based on the status code, if you want
      throw new Error(`HTTP error! status: ${raw.status}`);
    }

    // After data fetching read data
    const response = raw.json();
    return response;
  } catch (error) {
    console.error("error while fetching api:", error);
    throw error;
  }
}

// Global API POST function
export async function apiPost(requestPath, bodyJson, token = null) {
  // Create request options (if exists) with credentials
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    // Convert json body to string
    body: JSON.stringify(bodyJson),
    redirect: "follow",
  };

  try {
    // Create fetch url with parameters
    const url = global.api.base + requestPath;

    // Fetch data
    const raw = await fetch(url, requestOptions);

    // Check if the request was successful
    if (!raw.ok) {
      // You can throw a new error here based on the status code, if you want
      throw new Error(`HTTP error! status: ${raw.status}`);
    }

    // After data fetching read data
    const response = await raw.body;

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Global API POST function
export async function apiPostJson(requestPath, bodyJson, token = null) {
  // Create request options (if exists) with credentials
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    // Convert json body to string
    body: JSON.stringify(bodyJson),
    redirect: "follow",
  };

  try {
    // Create fetch url with parameters
    const url = global.api.base + requestPath;

    // Fetch data
    const raw = await fetch(url, requestOptions);

    // Check if the request was successful
    if (!raw.ok) {
      // You can throw a new error here based on the status code, if you want
      throw new Error(`HTTP error! status: ${raw.status}`);
    }

    // After data fetching read data
    const response = raw.json();

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function apiRequest(requestPath, bodyJson = {}, method = "POST", token = null) {
  // Create request options (if exists) with credentials
  const requestOptions = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      // Add the Authorization header only if a token is provided
      ...(token && { Authorization: "Bearer " + token }),
    },
    // Add the body property only if a body is provided
    ...(Object.keys(bodyJson).length > 0 && { body: JSON.stringify(bodyJson) }),
    redirect: "follow",
  };

  try {
    // Create fetch url with parameters
    const url = global.api.base + requestPath;

    // Fetch data
    const raw = await fetch(url, requestOptions);

    // Check if the request was successful
    if (!raw.ok) {
      // Fetch and include the response body in the error, if possible
      let errorBody;
      try {
        errorBody = await raw.json();
      } catch (error) {
        errorBody = await raw.text();
      }

      throw new Error(`HTTP error! status: ${raw.status}, body: ${errorBody}`);
    }

    // After data fetching, convert the response to JSON
    const response = await raw.json();

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
