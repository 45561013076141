export const propertyTypes = [
    {
        code: 'APT',
        value: 'Apartments',
        slug: 'apartments',
        residential: true,
        commercial: false,
    },
    {
        code: 'BUL',
        value: 'Bulk Units',
        slug: 'bulks',
        residential: true,
        commercial: true,
    },
    {
        code: 'COM',
        value: 'Compounds',
        slug: 'compounds',
        residential: true,
        commercial: false,
    },
    {
        code: 'DUP',
        value: 'Duplexes',
        slug: 'duplexes',
        residential: true,
        commercial: false,
    },
    {
        code: 'FAC',
        value: 'Factories',
        slug: 'factories',
        residential: false,
        commercial: true,
    },
    {
        code: 'FFL',
        value: 'Full Floors',
        slug: 'fullfloors',
        residential: true,
        commercial: true,
    },
    {
        code: 'HFL',
        value: 'Half Floors',
        slug: 'halffloors',
        residential: true,
        commercial: true,
    },
    {
        code: 'HTA',
        value: 'Hotel Apartments',
        slug: 'hotelapartments',
        residential: true,
        commercial: true,
    },
    {
        code: 'STF',
        value: 'Staff Accommodations',
        slug: 'staffaccommodations',
        residential: false,
        commercial: true,
    },
    {
        code: 'LAN',
        value: 'Lands/Plots',
        slug: 'landplots',
        residential: false,
        commercial: true,
    },
    {
        code: 'OFF',
        value: 'Offices',
        slug: 'offices',
        residential: false,
        commercial: true,
    },
    {
        code: 'PEN',
        value: 'Penthouses',
        slug: 'penthouses',
        residential: true,
        commercial: false,
    },
    {
        code: 'RET',
        value: 'Retails',
        slug: 'retails',
        residential: false,
        commercial: true,
    },
    {
        code: 'RES',
        value: 'Restaurants',
        slug: 'restaurants',
        residential: false,
        commercial: true,
    },
    {
        code: 'SHP',
        value: 'Shops',
        slug: 'shops',
        residential: false,
        commercial: true,
    },
    {
        code: 'SHW',
        value: 'Showrooms',
        slug: 'showrooms',
        residential: false,
        commercial: true,
    },
    {
        code: 'STO',
        value: 'Storages',
        slug: 'storages',
        residential: false,
        commercial: true,
    },
    {
        code: 'TWN',
        value: 'Townhouses',
        slug: 'townhouses',
        residential: true,
        commercial: true,
    },
    {
        code: 'VIL',
        value: 'Villas/Houses',
        slug: 'villas-houses',
        residential: true,
        commercial: true,
    },
    {
        code: 'BUI',
        value: 'Whole Buildings',
        slug: 'wholebuildings',
        residential: true,
        commercial: true,
    },
    {
        code: 'WAR',
        value: 'Warehouses',
        slug: 'warehouses',
        residential: false,
        commercial: true,
    },
    {
        code: 'BUN',
        value: 'Bungalows',
        slug: 'bungalows',
        residential: false,
        commercial: true,
    },
    {
        code: 'LFT',
        value: 'Lofts',
        slug: 'lofts',
        residential: true,
        commercial: false,
    },
    {
        code: 'HOT',
        value: 'Hotels',
        slug: 'hotels',
        residential: true,
        commercial: true,
    },
];

export const bedRooms = [
    "studio",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8+"
];

export const bathRooms = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6+",
];
