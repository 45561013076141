import { apiGet } from "./apiHelpers";

// GET Search location
export async function searchLocation(params, token) {
  try {
    const data = await apiGet("/locations/v1/public/locations", params, token);
    return data;
  } catch (error) {
    throw error;
  }
}
