import { global } from "@/config/global";
import { propertyTypes } from "@/dictionaries/types";
import Link from "next/link";
import React, { useState } from "react";
import { searchLocation } from "./locationHelpers";

export const capitalizeFirst = (str) => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } catch (error) {
    return str;
  }
};

export const capitalizeAllFirsts = (str) => {
  try {
    let array = str.split(" ");
    let newArr = [];
    array.forEach((element) => {
      newArr.push(capitalizeFirst(element));
    });
    return newArr.join(" ");
  } catch (error) {
    return str;
  }
};

// Get path string return path object
export function pathToJson(path) {
  try {
    const splitPath = path.split("?");

    let regexData = regexToJson(splitPath[0]);

    const pathArr = splitPath[0].split("/");

    let jsonArr = [];

    if (pathArr[2] === "properties") {
      jsonArr["lang"] = pathArr[1];
      jsonArr["slug"] = pathArr[3];
    } else if (pathArr[4] === undefined) {
      jsonArr["lang"] = pathArr[1];
      jsonArr["businessType"] = pathArr[2];
    } else if (pathArr[5] === undefined) {
      jsonArr["lang"] = pathArr[1];
      jsonArr["businessType"] = pathArr[2];
      jsonArr["locations"] = pathArr[3];
    } else if (pathArr[6] === undefined) {
      jsonArr["lang"] = pathArr[1];
      jsonArr["businessType"] = pathArr[2];
      jsonArr["locations"] = pathArr[4];
    } else {
      jsonArr["lang"] = pathArr[1];
      jsonArr["businessType"] = pathArr[2];
      jsonArr["locations"] = pathArr[5];
    }

    jsonArr["other"] = splitPath[1];

    let response = mergeArrays(regexData, jsonArr);
    return response;
  } catch (error) {
    throw new Error("Not Found");
  }
}
export function generateUniqueId(prefix) {
  return prefix + "_" + Math.random().toString(36).substr(2, 9);
}
// Get path string return path object
export function queryToJson(queryStr) {
  try {
    const query = queryStr.replace("?", "");
    const arr = query.split("&");
    let jsonArr = [];
    arr.forEach((a) => {
      let tempArr = a.split("=");
      let key = tempArr[0];
      let value = decodeURIComponent(tempArr[1]);
      jsonArr[key] = value;
    });
    return jsonArr;
  } catch (error) {
    throw new Error("Not Found");
  }
}

export function regexToJson(url) {
  try {
    const regExData = global.getPropertyType(url);

    if (regExData.type === "property") {
      return {
        businessType: regExData[2],
        propertyTypes:
          regExData[1] === "properties"
            ? null
            : propertyTypes.filter((type) => type.slug === regExData[1]).length > 0
              ? propertyTypes.filter((type) => type.slug === regExData[1])[0].code
              : null,
      };
    }

    if (regExData.type === "bedroomIncluded") {
      return {
        bedrooms: regExData[1],
        businessType: regExData[3],
        propertyTypes:
          regExData[2] === "properties"
            ? null
            : propertyTypes.filter((type) => type.slug === regExData[2]).length > 0
              ? propertyTypes.filter((type) => type.slug === regExData[2])[0].code
              : null,
        category: regExData[3] === "properties" ? regExData[1] : null,
      };
    }

    if (regExData.type === "comLux") {
      return {
        priceMin:
          regExData[1] === "luxury"
            ? regExData[3] === "rent"
              ? 500000
              : 10000000
            : null,
        businessType: regExData[3],
        propertyTypes:
          regExData[2] === "properties"
            ? regExData[1] === "commercial"
              ? propertyTypes
                .filter((type) => type.commercial === true)
                .map((type) => {
                  return type.code;
                })
              : null
            : propertyTypes.filter((type) => type.slug === regExData[2]).length > 0
              ? propertyTypes.filter((type) => type.slug === regExData[2])[0].code
              : null,
        category: regExData[2] === "properties" ? regExData[1] : null,
      };
    }

    if (regExData.type === "comLuxBedroom") {
      return {
        bedrooms: regExData[1],
        priceMin:
          regExData[2] === "luxury"
            ? regExData[3] === "rent"
              ? 500000
              : 10000000
            : null,
        businessType: regExData[4],
        propertyTypes:
          regExData[3] === "properties"
            ? regExData[2] === "commercial"
              ? propertyTypes
                .filter((type) => type.commercial === true)
                .map((type) => {
                  return type.code;
                })
              : null
            : propertyTypes.filter((type) => type.slug === regExData[2]).length > 0
              ? propertyTypes.filter((type) => type.slug === regExData[2])[0].code
              : null,
        category: regExData[3] === "properties" ? regExData[2] : null,
      };
    }

    return {};
  } catch (error) {
    throw new Error("Not Found");
  }
}

// Get query string return path object
export async function paramToJson(param) {
  if (param !== undefined && param !== null && param !== "") {
    let str = param.replace("?", "");
    let arr = str.split("&");
    let jsonArr = [];
    arr.forEach((a) => {
      let tempArr = a.split("=");
      let key = tempArr[0];
      let value = tempArr[1];
      jsonArr[key] = value;
    });
    return jsonArr;
  } else {
    return {};
  }
}

export function jsonToParam(jsonData) {
  if (jsonData !== undefined && jsonData !== null) {
    // Create an array to merge all parameters as a string.
    let paramString = [];
    // Create a temporary variable to create key value pair.
    let temp;

    // Get all the params from JSON
    Object.keys(jsonData).forEach(function (index) {
      // Check for null or empty data
      if (
        jsonData[index] !== null &&
        jsonData[index] !== "" &&
        jsonData[index] !== undefined
      ) {
        // Create string
        temp = index + "=" + jsonData[index].toString();
        // Add to arr
        paramString.push(temp);
      }
    });

    // return merged param string
    return "?" + paramString.join("&");
  } else {
    return "";
  }
}

export function mergeArrays(arr1, arr2) {
  try {
    let mergedArr = [];

    if (arr1 !== null && arr1 !== []) {
      Object.keys(arr1).forEach(function (index) {
        if (
          arr1[index] !== null &&
          arr1[index] !== undefined &&
          arr1[index] !== "" &&
          arr1[index] !== []
        ) {
          mergedArr[index] = arr1[index];
        }
      });
    }

    if (arr2 !== null && arr2 !== []) {
      Object.keys(arr2).forEach(function (index) {
        if (
          arr2[index] !== null &&
          arr2[index] !== undefined &&
          arr2[index] !== "" &&
          arr2[index] !== []
        ) {
          mergedArr[index] = arr2[index];
        }
      });
    }
    return mergedArr;
  } catch (error) {
    throw new Error("Not Found");
  }
}

export const createValidJsonBody = async (arr) => {
  if (arr["slug"] !== null && arr["slug"] !== undefined) {
    return arr;
  }

  const getLocationSlugs = async (locationInput) => {
    if (!locationInput) return null;
    try {
      const locationsSlugs = decodeURIComponent(locationInput);
      const locations = await searchLocation({slugs: locationsSlugs});
      return locations.locations.map((l) => l.slug).join(',');
    } catch (error) {
      return null;
    }
  };

  const validJsonBody = {
    businessType: arr["businessType"] || null,
    order: arr["order"] && !Number.isInteger(arr["order"]) ? arr["order"] : null,
    page: arr["page"] && parseInt(arr["page"]) >= 1 ? arr["page"] : null,
    locations: await getLocationSlugs(arr["locations"]),
    propertyTypes: arr["propertyTypes"] || null,
    bedrooms: arr["bedrooms"]
      ? arr["bedrooms"].replace("studio", 0)
      : arr["bedrooms"] || null,
    bathrooms: arr["bathrooms"] || null,
    priceMin: arr["priceMin"] === "Any" ? null : arr["priceMin"] || null,
    priceMax: arr["priceMax"] === "Any" ? null : arr["priceMax"] || null,
    sizeMin: arr["sizeMin"] === "Any" ? null : arr["sizeMin"] || null,
    sizeMax: arr["sizeMax"] === "Any" ? null : arr["sizeMax"] || null,
    furnishes: arr["furnishes"] || null,
    completionStatus: arr["completionStatus"] || null,
  };

  return validJsonBody;
}


export function urlBuilder(query) {
  let urlArr = [];
  urlArr.push(query.lang);
  urlArr.push(query.businessType);
  urlArr.push(query.city);
  urlArr.push(query.community);
  urlArr.push(query.subcommunity);
  urlArr.push(query.propertyType);
  let result = urlArr.filter((arr) => arr !== undefined);
  return "/" + result.join("/");
}

export function urlQueryBuilder(query) {
  const pathKeys = [
    "lang",
    "businessType",
    "city",
    "community",
    "subcommunity",
    "propertyType",
  ];
  let queryArr = [];
  Object.keys(query).forEach((key) => {
    if (!pathKeys.includes(key) && query[key] !== null && query[key] !== []) {
      queryArr.push(key + "=" + query[key]);
    }
  });
  return "?" + queryArr.join("&");
}

export function generateLink(query, slug) {
  let url;
  if (query.community === undefined) {
    url = urlBuilder({
      lang: query.lang,
      businessType: query.businessType,
      city: slug,
      community: query.city,
    });
  } else if (query.subcommunity === undefined) {
    url = urlBuilder({
      lang: query.lang,
      businessType: query.businessType,
      city: query.city,
      community: slug,
      subcommunity: query.community,
    });
  } else if (query.propertyType === undefined) {
    url = urlBuilder({
      lang: query.lang,
      businessType: query.businessType,
      city: query.city,
      community: query.community,
      subcommunity: slug,
      propertyType: query.subcommunity,
    });
  } else {
    url = "";
  }
  return url;
}

export function renderMapLinks(query, list) {
  return (
    <>
      <div className="row">
        {list.slice(0, 5).map((item) => {
          let link = generateLink(query, item.urlSlug);
          return (
            <>
              <div className="col-md-2 links-underlined">
                <Link
                  key={item.id}
                  name={item.urlSlug}
                  href={link}
                  title={`Properties for ${query.businessType} in ${item.name}`}
                  className="nav-link m-2 float-left"
                >
                  {item.name}
                </Link>
              </div>
            </>
          );
        })}
      </div>
      <div id="accordion-5" className="accordion" role="tablist">
        <div>
          <div
            className="accordion-collapse collapse item-5"
            role="tabpanel"
            data-bs-parent="accordion-5"
          >
            <div>
              <div className="row">
                {list.slice(5, list.length).map((item) => {
                  let link = generateLink(query, item.urlSlug);
                  return (
                    <>
                      <div className="col-md-2 links-underlined">
                        <Link
                          key={item.id}
                          name={item.urlSlug}
                          title={`Properties for ${query.businessType} in ${item.name}`}
                          href={link}
                          className="nav-link m-2 float-left"
                        >
                          {item.name}
                        </Link>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function translate(what, toWhat) {
  try {
    switch (toWhat) {
      case "propertyTypeName":
        return propertyTypes.filter((type) => type.slug === what)[0].value;

      default:
        return what;
    }
  } catch (error) {
    return what;
  }
}

export function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

export function devLog(at = null, content) {
  if (global.env === "development") {
    if (at !== null) {
      console.log(at);
    }
    console.log(content);
  }
}

export const todayTime = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based in JavaScript
  let day = date.getDate().toString().padStart(2, "0");

  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export function queryStringReducer(query) {
  let reducedQuery = [];
  Object.keys(query).forEach(function (index) {
    if (
      query[index] !== null &&
      query[index] !== "" &&
      query[index] !== undefined &&
      query[index] !== [] &&
      query[index] !== "Any" &&
      query[index] !== "0"
    ) {
      reducedQuery[index] = query[index];
    }
  });

  return reducedQuery;
}
